import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDocument } from "../../interfaces/document";
import moment from "moment";

interface IDocumentSliceInitialState {
  pageNumber: string;
  selectedDateRange: IDateRange;
  selectedMetros: string[];
  isAllSelected: boolean;
  metros: any[]
}

interface IDateRange {
  fromDate: string | null; toDate: string | null;
}

export const documentInitialState: IDocumentSliceInitialState = {
  pageNumber: "1",
  selectedDateRange: { 
    fromDate: moment().startOf('month').set({hour: 0, minute: 0, second: 0})?.toString(), 
    toDate: moment().set({hour: 23, minute: 59, second: 59})?.toString() 
  },
  selectedMetros: [],
  isAllSelected: true,
  metros: []
};

export const documentSlice = createSlice({
  name: "document",
  initialState: documentInitialState,
  reducers: {
    setPageNumberForDocument: ( state: IDocumentSliceInitialState, { payload }: PayloadAction<string> ) => {
      state.pageNumber = payload;
    },
    setSelectedDateRange: (state: IDocumentSliceInitialState, { payload }: PayloadAction<IDateRange>) => {
      state.selectedDateRange = payload;
    },
    setSelectedMetros: (state: IDocumentSliceInitialState, { payload }: PayloadAction<string[]>) => {
      state.selectedMetros = payload;
    },
    setIsAllSelected: (state: IDocumentSliceInitialState, { payload }: PayloadAction<boolean>) => {
      state.isAllSelected = payload;
    },
    setMetros: (state: IDocumentSliceInitialState, { payload }: PayloadAction<any[]>) => {
      state.metros = payload;
    }
  },
});

export const { setPageNumberForDocument, setSelectedMetros, setIsAllSelected, setSelectedDateRange, setMetros } =
  documentSlice.actions;
export default documentSlice.reducer;
