import React from "react";
import { Statistic, Row } from "antd";
import { FileTextOutlined, FileOutlined } from "@ant-design/icons";

interface ApiCallStatisticsType {
  pageCount: TokenData;
  totalDocuments: number;
}

type TokenData = {
  date: string;
  pageCount: {
    service_name: string;
    pageCount: string;
    token_count: number;
  }[];
}[];

const COLORS = [
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
  "#DA7C7C",
  "#7280CD",
  "#5EADA6",
  "#E2B039",
];

const BACKGROUND = [
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
  "#FFF0EA",
  "#DDE1F7",
  "#D1E9E7",
  "#F7EBCF",
];

const CopilotStatistic = ({
  title,
  value,
  total,
  color,
  backgroundColor,
  suffix,
  icon,
}: {
  title: string;
  value: number;
  total: number;
  color: string;
  backgroundColor: string;
  suffix: string;
  icon: React.ReactNode;
}) => (
  <div className="flex gp-10 m-t items-center"> 
    <div className="icon_Statistics"
      style={{
        backgroundColor: backgroundColor
      }}
    >
      {icon}
    </div>
    <Statistic
      title={title}
      value={value}
      suffix={suffix}
      valueStyle={{ color: color, fontSize: "20px", fontWeight: "600" }}
    />
  </div>
);

const ApiCallStatistics = ({ pageCount, totalDocuments }: ApiCallStatisticsType) => {
  const transformData = (tokenData: TokenData) => {
    const result: Record<string, number> = {
      Pages: 0,
      Placeholders: 0,
      "Average Pages": 0,
      "Average Placeholders": 0,
    };

    if (!tokenData || tokenData.length === 0) {
      return result;
    }

    tokenData.forEach(({ pageCount }) => {
      pageCount.forEach(({ service_name, token_count }) => {
        if (!result[service_name]) {
          result[service_name] = 0;
        }
        result[service_name] += token_count;
      });
    });

    if (totalDocuments > 0) {
      result["Average Pages"] = parseFloat(
        (result["Pages"] / totalDocuments).toFixed(1)
      ) || 0;
      result["Average Placeholders"] = parseFloat(
        (result["Placeholders"] / totalDocuments).toFixed(1)
      ) || 0;
    }

    return result;
  };

  const copilotTokenCount = transformData(pageCount);

  return (
    <div className="statistics">
      <h4 className="summary-title">
        Pages and placeholders Statistics Overview
      </h4>
      <p>Summary description for Pages and placeholders statistics overview</p>
      <Row className="grid grid-cols-2">
        {copilotTokenCount &&
          Object.entries(copilotTokenCount).map(
            ([service_name, count], index) => {
              const suffix = service_name.includes("Pages")
                ? "Pages"
                : "Placeholders";

              
              let icon;
              const iconStyle = {
                color: COLORS[index % COLORS.length],
                fontSize: "24px", 
              };

              if (service_name === "Pages") {
                icon = <FileOutlined style={iconStyle} />;
              } else if (service_name === "Placeholders") {
                icon = <FileTextOutlined style={iconStyle} />;
              } else if (service_name === "Average Pages") {
                icon = <FileOutlined style={iconStyle} />;
              } else if (service_name === "Average Placeholders") {
                icon = <FileTextOutlined style={iconStyle} />;
              }

              return (
                <CopilotStatistic
                  key={service_name}
                  title={service_name}
                  value={count}
                  total={Object.values(copilotTokenCount).reduce(
                    (acc, val) => acc + val,
                    0
                  )}
                  color={COLORS[index % COLORS.length]}
                  backgroundColor={BACKGROUND[index % BACKGROUND.length]}
                  suffix={suffix}
                  icon={icon}
                />
              );
            }
          )}
      </Row>
    </div>
  );
};

export default ApiCallStatistics;