import React, { useEffect } from 'react';
import { Select, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setSelectedMetros, setIsAllSelected, setPageNumberForDocument } from '../store';
import './fall-back.scss';

const { Option } = Select;

interface MetroFilterProps {
  metros: any[];
  onApply: (selectedMetros: string[]) => void;
}

export const MetroFilter: React.FC<MetroFilterProps> = ({ metros, onApply }) => {
  const dispatch = useDispatch();
  const { selectedMetros, isAllSelected } = useSelector((state: RootState) => state.document);

  useEffect(() => {
    if (isAllSelected && metros.length > 0 && selectedMetros.length === metros.length) {
      onApply(selectedMetros);
    }
  }, [metros, isAllSelected, selectedMetros, onApply]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleChange = (values: string[]) => {
    const allOptionIndex = values.indexOf('all');
    
    if (allOptionIndex !== -1) {
      if (isAllSelected) {
        // Clear selection
        dispatch(setSelectedMetros([]));
        onApply([]);
        dispatch(setIsAllSelected(false));
      } else {
        // Select all
        const allMetroNames = metros.map(metro => metro.name);
        dispatch(setSelectedMetros(allMetroNames));
        onApply(allMetroNames);
        dispatch(setIsAllSelected(true));
      }
    } else {
      dispatch(setSelectedMetros(values));
      dispatch(setPageNumberForDocument("1"))
      onApply(values);
      dispatch(setIsAllSelected(values.length === metros.length));
    }
  };

  return (
    <div className="metro-filter" >
      <Select
        mode="multiple"
        placeholder="All"
        style={{ width: '100%', minWidth: '120px', cursor: 'pointer' }}
        value={selectedMetros}
        onChange={handleChange}
        showSearch={true}
        maxTagCount={0}
        maxTagPlaceholder={() => `${selectedMetros.length}`}
        optionLabelProp="label"
        className="custom-select-no-ticks"
        menuItemSelectedIcon={null}
      >
        <Option key="all" value="all" label={'Select All'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={isAllSelected} style={{ marginRight: 8 }} />
            <span style={{ fontWeight: 'bold' }}>{ 'Select All'}</span>
          </div>
        </Option>
        
        {metros.map((metro) => (
          <Option 
            key={metro.name} 
            value={metro.name} 
            label={capitalizeFirstLetter(metro.name)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={selectedMetros.includes(metro.name)} style={{ marginRight: 8 }} />
              <span>{capitalizeFirstLetter(metro.name)}</span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};